import React from 'react';
import './App.css';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';


class CameraComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      camera_user: "",
      camera_password: "",
      show_camera: false,
      cam_url: "",
      counter: 1
    };
    this.showCamera = this.showCamera.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );


  }

  tick() {
    let cntIncrement = this.state.counter + 1
    if (cntIncrement > 60) {
      this.setState({
        cam_url: ""
      });
    }
    else {
      this.setState({
        counter: cntIncrement
      });
    }
  }


  showCamera() {
    this.setState(prevState => ({
      ...prevState,
      show_camera: true,
      counter: 0,
      cam_url: "https://resocamera.axellundh.se/"
    }));


  }


  componentWillReceiveProps({ cameraCredentials }) {
    let newState = {
      camera_user: cameraCredentials.camera_user,
      camera_password: cameraCredentials.camera_password,
    };



    this.setState(newState)
  }
  render() {
    let camurl = this.state.cam_url




    return (
      <div>
        <Typography variant="h4" color="inherit">
          Kamera
      </Typography>




        <div>
          <video width="1" height="1" src="https://resocamera.axellundh.se/" controls>
            Your browser does not support the <code>video</code> element.
</video>
          <img
            src={camurl} width="100%"
            alt=""
          />
        </div>

        <div><Button variant="contained" color="primary" onClick={this.showCamera}>
          Visa Kamera </Button> </div>
      </div>
    );
  }
}


export default CameraComponent;
