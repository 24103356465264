import React, { Component } from 'react';
import './App.css';
import CameraComponent from './CameraComponent'
import CurrentTemp from './CurrentTemp'
import NavBar from './NavBar'
import firebase from "./FireStore";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';




import Grid from '@material-ui/core/Grid';

//App theme imports
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
});

const Muitheme = createMuiTheme({
  palette: {
    primary: {
      light: '#439889',
      main: '#00695c',
      dark: '#003d33',
      contrastText: '#fff',
    },
    secondary: {
      light: '#62717b',
      main: '#37464f',
      dark: '#101f27',
      contrastText: '#000',
    },
  },
  typography: {
    useNextVariants: true,
  },
  spacing: {
    unit: 1
  },
}
);

class App extends Component {


  constructor() {
    super();
    this.state = {
      camera_user: "axel",
      camera_password: "",
      email: "",
      password_input: "",
      email_input: ""
    };
    this.handleCredentialsInputChange = this.handleCredentialsInputChange.bind(this);
    this.logIn = this.logIn.bind(this);
  }






  logIn() {
    let password = this.state.password_input;
    let email = this.state.email_input;

    firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {

      this.setState({
        camera_password: this.state.password_input,
        password_input: "",
        email_input: ""
      });

    }).catch(function (error) {
      console.log(error.code)
      console.log(error.message)
    });


  }


  handleCredentialsInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  render() {
    let cameraCredentials = {
      camera_user: this.state.camera_user,
      camera_password: this.state.camera_password
    }
    return (
      <MuiThemeProvider theme={Muitheme}>
        <div className="App">
          <Grid container spacing={24} direction="row" justify="center">
            <Grid item xs={12}>
              <NavBar />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper >
              
                <CameraComponent cameraCredentials={cameraCredentials}></CameraComponent>
            
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper >
                <CurrentTemp login={true}></CurrentTemp>
              </Paper >
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid >
                  <Grid item xs={12}>
                    <TextField type="email" label="E-mail"name="email_input" onChange={this.handleCredentialsInputChange} value={this.state.email_input} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField type="password" label="Password" name="password_input" margin="normal" onChange={this.handleCredentialsInputChange} value={this.state.password_input}  />
                  </Grid>
                  <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={this.logIn}>
                  Logga in </Button> 
                  </Grid>
                  
        </Grid>
        </Paper>
          </Grid>
          </Grid>


      
        </div>
      </MuiThemeProvider >
    );
  }
}
export default withStyles(styles)(App);
