import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
const config = {
    apiKey: "AIzaSyDDwWk3qvve75qoe2jak8yfUTzXhLEvpm4",
    authDomain: "resotemperatur.firebaseapp.com",
    databaseURL: "https://resotemperatur.firebaseio.com",
    projectId: "resotemperatur",
    storageBucket: "resotemperatur.appspot.com",
    messagingSenderId: "54096837606"
};
firebase.initializeApp(config);

export default firebase;