import React from 'react';
import firebase from "./FireStore";
import Typography from '@material-ui/core/Typography'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';


class CurrentTemp extends React.Component {
  constructor() {
    super();
    this.state = {
      lastTemp: "",
      lastUpdate: "",
      sensor_log: []
    };
  }
  componentWillReceiveProps({ login }) {
    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.

      this.readHistoricalData()
      this.readCurrentTemperature()
    } else {
      // No user is signed in.
    }
  }

  componentDidMount() {
      this.readHistoricalData()
      this.readCurrentTemperature()
  }
  readCurrentTemperature() {
    const db = firebase.firestore();
    db.collection("sensors").doc("1").get().then((querySnapshot) => {
      return querySnapshot.data()['sensor_info'];
    }).then((sensorInfo) => {
      this.setState({ lastTemp: sensorInfo['last_temperature'] });
      return sensorInfo['last_time_online']['seconds'];
    }).then((seconds) => {
      var utcseconds = seconds * 1000
      var t = new Date(utcseconds);
      var formatteddate = t.toLocaleString("sv-SE");
      return formatteddate
    }).then((formatteddate) => {

      this.setState({ lastUpdate: formatteddate });
    })

  }
  readHistoricalData() {

    const db = firebase.firestore();
    const sensor_log = []
    db.collection('sensors').doc("1").collection('sensor_log').orderBy("logged_at", "desc").limit(100).get().then((querySnapshot) => {

      querySnapshot.forEach(function (doc) {
        var docData = doc.data()
        var utcseconds = docData['logged_at']['seconds'] * 1000
        var t = new Date(utcseconds);
        var formatteddate = t.toLocaleString("sv-SE");
        var tempReading = { logged_at: formatteddate, temperature: docData['current_temperature'] }
        sensor_log.push(tempReading)

      });

    }).then(() => {

      this.setState({ sensor_log: sensor_log.reverse() });
    })

  }

  render() {
    return (
      <div>
        <Typography variant="h4" color="inherit">
          Senaste temperatur
      </Typography>
        Temperatur: {this.state.lastTemp} C, {this.state.lastUpdate}
        <Typography variant="h4" color="inherit">
          Temperatur historik
      </Typography>

        <ResponsiveContainer width="100%" height={500} minWidth={100} minHeight={100}>
          <LineChart width={730} height={250} data={this.state.sensor_log}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="logged_at" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="temperature" name="Temperatur Celcius" stroke="#003d33" />
          </LineChart>
        </ResponsiveContainer>



      </div>

    );

  }
}

export default CurrentTemp;
